@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .border-gradient {
      @apply border border-transparent;
      background-image: linear-gradient(#fff8e4, #fff8e4), linear-gradient(#d0011b, #ffbd34);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    .w-card {
      min-width: 160px;
    }

    .h-card {
      height: 268px;
    }

    .h-zone {
      height: 303px;
    }

    .h-header-img {
      height: 26px;
    }

    .w-header-img {
      width: 375px;
    }

    .h-candidate {
      height: 232px;
    }

    .h-button {
      height: 36px;
    }

    .arrow-shape {
      content: '';
      position: absolute;
      right: -0.25rem;
      width: 0;
      height: 0;
      border-width: 0.625rem 0.25rem;
      border-style: solid;
      border-color: #26aa99 transparent;
    }

    .scroll-smooth {
      scroll-behavior: smooth;
    }

    .list-space::after,
    .list-space::before {
      content: '';
      min-width: 0.5rem;
      @apply h-card;
    }

    .carousel-arrow {
      @apply top-1/2 absolute flex w-9 h-9 large:w-12 large:h-12 items-center justify-center cursor-pointer rounded-full bg-gray-50 drop-shadow-lg transition;
    }

    @media (min-width: 960px) {
      .h-header-img {
        height: 46px;
      }

      .w-header-img {
        width: 633px;
      }

      .w-card {
        min-width: 455px;
      }

      .h-card {
        height: 756px;
      }

      .h-candidate {
        height: 698px;
      }

      .h-button {
        height: 102px;
      }

      .h-zone {
        height: 989px;
      }

      .list-space::after,
      .list-space::before {
        min-width: 0.75rem;
      }

      /* .arrow-shape {
        right: -0.5rem;
        border-width: 1rem 0.5rem;
      } */
    }

    @media (max-width: 375px) {
      .h-header-img {
        height: 7vw;
        max-height: 26px;
      }

      .w-header-img {
        width: 100%;
      }
    }
  }
}

html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', 'Noto Sans TC', sans-serif;
}

#__next {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

b {
  font-weight: 500;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Regular.woff') format('woff'), url('/fonts/Shopee2021-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Medium.woff') format('woff'), url('/fonts/Shopee2021-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Bold.woff') format('woff'), url('/fonts/Shopee2021-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}
